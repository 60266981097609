import React from "react";
import { graphql } from "gatsby";

export default function Form({
  data: { form, queryShowHidePlaces },
  pageContext,
}) {
  return <></>;
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query ($name: String!, $language: String!) {
    form: sanityForms(
      formName: { eq: $name }
      language: { eq: $language } # filter: { language: { eq: $language } }
    ) {
      formName
      reasonsList
      id
      _rawText
    }
    queryShowHidePlaces: allSanityShowPlacesProgramm {
      nodes {
        klubShowProgramm
        klubShowLocation
        klubShowMieten
        saalShow
        wiengartenShow
        biergartenShow
      }
    }
  }
`;
